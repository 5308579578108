import _cssResult from '@/sass/glycomb.scss';
/**
 * This file is supposed to be used as the entry point to load the GlyComb stylesheet data (i.e. sass/css stylesheets data) from .js/ts files.
 * Webpack will compile this file during the compilation process and all stylesheet data will be stored to another bundle js file.
 * To load the result bundled stylesheet data from .js/ts files, use dynamic imports, i.e. import().
 * As a result, size per file will be reduced.
 */
export const cssResult = _cssResult;
/**
 * Our stylesheet data is assumed to be used in the web component objects provided by Lit.
 * However, several light DOM elements also need to be styled in the same fashion.
 * Therefore, we need to reflect the stylesheet data to the light DOM first.
 */
export function reflectStylesToLightDOM() {
    const style = document.createElement('style');
    style.setAttribute('name', 'glycomb-style');
    style.innerHTML = cssResult.cssText;
    document.getElementsByTagName('head')[0].appendChild(style);
}
